body {
  /*
  background: #536976;  
  background: -webkit-linear-gradient(to bottom, #292E49, #536976);  
  background: linear-gradient(to bottom, #292E49, #536976); 
  */
  /*Ocultando overflow     overflow: hidden;*/


  background: #eee !important;
  overflow: hidden;
  font-weight: 500;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*
  font-family:  "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show>.btn-default.dropdown-toggle {
  background-color: #fff !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}


.mdb-skin .btn-default:not([disabled]):not(.disabled):active,
.mdb-skin .btn-default:not([disabled]):not(.disabled).active,
.show>.mdb-skin .btn-default.dropdown-toggle {
  background-color: transparent !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}






code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.side-nav {
  top: '65px',
}

.table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500 !important;
}



.classic-tabs .nav li a.active {
  color: white;
  border-bottom: 3px solid;
}

.classic-tabs .nav li a {
  padding: 8px !important;
}



.menu {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.menu li {
  display: inline-block;
  list-style: none;
}

.menu li:not(:nth-last-child(4)) {
  margin: 0 0px;
}

.menu li:nth-last-child(4) {
  margin-left: auto;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgb(188, 186, 187);
}

/*Tree cambio de estilos*/
.ant-tree-treenode-switcher-close {
  text-align: left;
}

.ant-tree-treenode-switcher-open {
  text-align: left;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgb(188, 186, 187);
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: rgb(236, 233, 235);
}

.tratarimagen {
  min-width: "35px";
  max-height: "65px"
}

.carousel-indicators {
  background-color: #485563;

}

.ant-tree li {
  margin: 0;
  padding: 0px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

.mdb-datatable table tr {
  border-bottom: 1px solid #dee2e6;
  text-align: left;

}




/*Image viewer references */
.react-simple-image-viewer__modal {
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  /*
  padding: 155px 60px 0px 60px;
  */
  right: 0;
  top: 0;

  height: 100%;
  width: 100%;
  background-color: none repeat scroll 0 0 #000;
  box-sizing: border-box;
  z-index: 9999;
}

.react-simple-image-viewer__modal-content {

  /*background-color: #fff;*/

  margin: 50px auto 0px auto;
  text-align: center;
  justify-content: center;
  height: calc(100vh - 80px);
  width: calc(100vw - 70px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-simple-image-viewer__modal-content img {
  width: calc(100vw - 800px);
  object-fit: cover;
  object-position: center center;
}

@media (max-width: 900px) {
  .react-simple-image-viewer__modal-content img {
    width: calc(100vw - 100px);
  }
}



.react-simple-image-viewer__close {
  color: white;
  position: absolute;
  top: 17px;
  margin-left: 23px;
  /*right: 30px;*/
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.react-simple-image-viewer__span {
  color: white;
  position: absolute;
  top: 20px;
  margin-left: 70px;
  /*right: 30px;*/
  font-size: 16px;
  font-weight: bold;
}



.modalCoche {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  overflow: auto;
  /* Enable scroll if needed */
  margin-left: 40%;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
}

.box::-webkit-input-placeholder {
  color: #fff !important;
}

.box:-moz-placeholder {
  color: #fff !important;

}

.box::-moz-placeholder {
  color: #fff !important;
}


.boxCoche::-webkit-input-placeholder {
  color: #bfccd6 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active {
  background-color: #c9c9c9 !important;
  color: #000;

}

.white-text,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active,
.side-nav,
.side-nav .search-form .form-control,
.md-toast-message a,
.md-toast-message label,
.md-toast-close-button,
#toast-container>div,
.clockpicker_container.clockpicker .picker__box .clockpicker-am-pm-block .active,
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected,
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus,
.picker--time .picker__button--clear:hover::before,
.picker--time .picker__button--clear:focus::before,
.picker__date-display,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-am.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.clockpicker-display .clockpicker-display-column #click-am.text-primary,
.clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.darktheme .picker__box .picker__date-display .clockpicker-display,
.darktheme .picker__box .picker__date-display .clockpicker-display .clockpicker-span-am-pm,
.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick,
.darktheme .picker__box .picker__footer button,
.picker__box .picker__header .picker__date-display,
.picker__box .picker__table .picker__day--outfocus,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover,
.picker__box .picker__table .picker--focused {
  color: #000 !important;
}


.mdb-skin input[type="text"]:focus:not([readonly]),
.mdb-skin input[type="password"]:focus:not([readonly]),
.mdb-skin input[type="number"]:focus:not([readonly]),
.mdb-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #dcdcdc !important;
  box-shadow: 0 0px 0 0 #dcdcdc !important;
}

.estiloBuscadorOE:focus {
  border-style: none !important;
  outline: none !important;
}

/*Efecto botón*/
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


.mdb-skin .btn-default {
  background-color: #010910 !important;
  box-shadow: none !important;
}

.mdb-skin .btn-matricula {
  background-color: #eee !important;
  box-shadow: none !important;
}



.mdb-skin .btn-matriculav2 {
  background-color: transparent !important;
  box-shadow: none !important;
}

/*Solo para el icono del usuario*/
.nav-link {
  padding: .0rem 0rem !important;
}

.mdb-skin .btn-matriculav3 {
  background-color: #fff !important;
  box-shadow: none !important;
}


.navSup .nav li:first-child {
  margin-left: 0px !important;
}

.botonFinalMatricula {
  padding: 0px !important;
  background-color: #eee !important;
}



.icono-separacion :after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 60%;
  transform: translateY(-50%);
  background-color: #999999;
}

.selectorBuscadorMatricula {
  border: 0px !important;
  background-color: transparent !important;
}

.inputCoche {
  background-color: #eee;
  /* margin-left: .25rem !important;*/
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #eee;
}

.colorGris {
  background-color: #eee !important;

}

.input-group-prepend {
  margin-right: -4px;
}


.mdb-skin .btn-secondary {
  background-color: #eee !important;
  color: #000 !important;
  box-shadow: none !important;
}

.mdb-skin .boton-terciario {
  background-color: #eee !important;
  color: #000 !important;
  box-shadow: none !important;
}

.mdb-skin .boton-Coche {
  background-color: transparent !important;
  font-size: 14px;
  color: #333 !important;
  box-shadow: none !important;
  text-transform: none !important;
}



.mdb-skin .btn-secondary.dropCoche {
  background-color: transparent !important;
}

.mdb-skin .btn-secondary.dropCoche:hover {
  background-color: transparent !important;
}

.mdb-skin .btn-secondary.dropCoche:focus {
  background-color: transparent !important;
}

/*Cambio de color efecto azul dropDown*/
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active,
.mdb-skin .btn-secondary:not([disabled]):not(.disabled).active,
.show>.mdb-skin .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}

/*028047*/

.estilosbotonGrafico {
  cursor: pointer;
  background-color: #eee !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 60% !important;
}

.estilosbotonBucarTree {
  cursor: pointer;
  background-color: #eeeeee !important;
  border: none !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 60% !important;
}

.estilosbotonBucarTree:hover {
  background-color: #d8d7d7 !important;
}



.coloAzul {
  background-color: #000000 !important;
}


.mdb-skin .btn-coloAzulBajo {
  background-color: #1a3651 !important;
  box-shadow: none !important;
}

.colorSubAzul {
  color: #021323 !important;
}

.btn-buscador {
  background-color: #eee !important;
  box-shadow: none !important;
}

.botonRedondoDrawer {
  color: #fff;
  background-color: #010910;
  border: none;
  width: 41px !important;
  height: 40px !important;
}


/*
.active, .btnModal:hover {
  background-color: #666;
  color: white;
}
*/


.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #dcdcdc
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}


.centerMenu2 {
  margin: auto;
  width: 100%;
  padding: 10px;
  background-color: #dcdcdc;
  text-align: left;
}




/* Estilos para el drawer principal submenu*/
.flex-container_sub {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-align: center;
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-align: center;
}

.flex-item-left {

  padding: 16px 5px;
  flex: 35%;
}

.flex-item-right {

  padding: 10px;
  flex: 50%;
}

@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
}



/*Sistema grid*/
.grid-container {
  display: inline-flex;
  grid-template-columns: auto auto auto;
  /*margin-left: 10px;*/
  vertical-align: bottom;
  background-color: #eee;
  border-radius: 3px 3px 3px 3px !important;
  border: 0px solid #eee;
  padding: 2px 8px 2px 8px;
  height: 40px;

}

.grid-item {
  background-color: #eee;

  font-size: 16px;
  text-align: center;
}

.solorMargen {
  margin-top: 2px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #dcdcdc;
}


.barraLateral {
  background-color: #ccc;
  width: 2px;
  height: 28px;
  margin-top: 4px;
}

.estiloBotonRemove {
  border: 0 !important;
  padding: 6px 7px 5px 3px !important;
  margin: 0 !important;
}

.activebtnMenu2:hover {
  background-color: #eee !important;

}

.activebtnMenu3:hover {
  background-color: #000 !important;
}

.dropdown-menu {
  background-color: #dcdcdc !important;
}

.soloColorBlanco {
  color: #fff !important;
}






.soloColorNegro {
  color: #000 !important;
}


.colorDrawerMenu2 {
  color: #dcdcdc !important;
}

.backgroundNegro {
  background-color: #000 !important;
}


.backgrounNegroClaro {
  background-color: #2f2f2f !important;
}

.backgroundGris {
  background-color: #dcdcdc !important;
}


.text {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  word-break: break-all;
  margin: 7px 5px;
}

.barraLateralDiagonal {
  background-color: #ccc;
  width: 2px;
  height: 28px;
  margin-top: 4px;
  transform: skew(-21deg);
}


.flex-container-Grow-Simple {
  display: flex;
  align-items: center;

}


.flex-container-Grow-Tercero {
  display: flex;
  align-items: center;
  background-color: #2f2f2f;
}

.flex-container-Grow-Tercero>div {
  color: #fff;
}


.flex-container-Grow {
  display: flex;
  align-items: center;
  background-color: #000;
}


.flex-container-Grow>div {
  background-color: #000;
  color: #fff;
}


.estiloContainerTree {
  margin-top: 7.8rem !important;
}

table.table td,
table.table th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}



.spantitle {
  font-size: 16px;
}


.containerUl {
  position: absolute;
  width: 95%;
  text-align: left;

  z-index: 989;
  cursor: default;

  /*
  height: 100px;
  background-color: red;
  */
}

.containerFilroTree {
  position: absolute;
  /*width: 84%;*/
  width: 150%;
  text-align: left;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  display: block;
  flex-direction: column;
  list-style-type: none;
  margin-left: 1px;
  margin-top: -5px !important;
  padding: 0;
  border: 0;
  border-radius: 0 !important;
  /* overflow: hidden; */
}


.containerFilroTree ul {
  list-style-type: none;
  list-style-position: outside;
  padding: 6px 0px 0px 18px;
}

.ulTree {
  flex: auto;
}

.li_tree {
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
  font-size: 14px;
}

.ulTree .li_tree:hover {
  background-color: #dcdcdc;
  color: #000 !important;
}


.containerReferencia {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: auto;
  padding: 0px !important;
  padding-top: 5px !important;


}

.containerReferenciaItem {
  background-color: rgba(255, 255, 255, 0.8);
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
  padding: 0px;
  font-size: 16px;
  text-align: left;
}

.nombreArticulo {

  margin-bottom: 10px;
  border-bottom: 1px solid #2c3e50;
}

.containerReferencia .marcaArticuloTitulo {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 18px;



}


.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}


.pagination a.active {
  background-color: #8c8c8c;
  color: #fff;
  border: 1px solid #8c8c8c;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  ;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.estiloTexto {
  font-size: 16px !important;
  color: #000 !important;
}

.columnaIcono {
  padding: 0px !important;
  vertical-align: middle;
}

.rowReferencia {
  padding: 5px 0px;
}

.rowReferencia:hover {
  background-color: #4285f4;
}


.breadcrumb-item+.breadcrumb-item::before {
  color: #fff !important;
}

.classic-tabs .nav li:first-child {
  margin-left: 0px !important;
}

.margenDiv {
  margin-top: 70px !important;
}

.estiloIcono {
  font-size: 22px;
}

.headerTabReferencia {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 5px !important;
}

.tab-content>.active {
  display: block;
}

.activeArcauto {

  opacity: 0.5 !important;
  cursor: pointer !important;
}


.containerSpinner {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
  min-height: '70vh'
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 8px 8px 0px !important;
  text-align: left !important;
}



.svg-container {
  height: 100%;
  width: 100%;
  max-height: 15cm;
  max-width: 15cm;
  margin: 0 auto;
}


.menuGraficos {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  background-color: #fff;
  flex: 0 0 200px;

  max-width: 400px !important;
  min-width: 400px !important;
  width: 400px !important;
}

.estiloDrawerHeader {
  position: fixed;

}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  vertical-align: middle !important;
}


.ant-tree li {
  padding-bottom: 3px !important;
}








.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}



/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}




.next-number-picker {
  display: inline-block;
}

.number-picker {
  position: relative;
  display: inline-block;
}

.product-quantity .product-quantity-info {
  margin-left: 8px;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
}

.product-warranty {
  margin-top: 18px;
}

.product-shipping {
  margin-top: 18px;
}




.next-input-group {
  display: inline-table;
  border-collapse: separate;
  border-spacing: 0;
  line-height: 0;
  width: 100%;
}

.zoro-ui-number-picker.next-number-picker .next-btn {
  border-radius: 50% !important;
  border: none;
  background: #f2f2f2;
  width: 30px;
  height: 30px;
}


.next-input-group-addon {
  width: 1px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.next-input.next-medium input {
  height: 34px;
  text-align: center;
  font-size: 12px;
  border: transparent;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 10px;
  width: 50px;
  border: 1px solid #dcdcdc;
}

.txtCantidad:focus {
  outline: none;
}

.paddindDeTres {
  padding: 3px !important;
}


.alertify-notifier {
  /*position: fixed;*/
  /*z-index: 99999999999999999999999999999999999999999999999999999999 !important;*/
  z-index: 999999999999999999999999;

}

.alertify-notifier ajs-bottom ajs-right {
  z-index: 999999999999999999999999 !important;
}

.alertify-notifier ajs-bottom ajs-left {
  z-index: 999999999999999999999999 !important;
}

.alertify-notifier .ajs-message.ajs-success {
  background-color: #4285f4;
}

div.container6 {
  display: flex;
  align-items: center;
  justify-content: center;
}


.refComercial {
  color: #4285f4 !important;
  cursor: pointer;
}

.refComercial:hover {
  color: #3164b6 !important;
  cursor: pointer;
}


.ant-list-item-meta {
  padding-bottom: 7px !important;
  padding-top: 7px !important;
  padding-left: 7px !important;
}




.ant-list-item-action {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -140px !important;
  padding: 0;
  font-size: 0;
  list-style: none;
}


.ant-list-item-action .vehiculoReferencia {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -190px !important;
  padding: 0;
  font-size: 0;
  list-style: none;
}





.ant-list-item-meta:hover {
  background-color: #dddd !important;
  cursor: pointer;
}


.ant-tabs-tab {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000 !important;
}

.orderTable {
  font-size: 14px !important;
  cursor: pointer !important;
  vertical-align: baseline;
  margin-left: 5px;
}

.orderTable:hover {
  color: #4285f4;

}

.anticon-search {
  vertical-align: baseline !important;
}










.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  visibility: hidden !important;
}





.drawer.impresion .page-content .sub-menu {
  height: 40px;
  background-color: #323639;
  display: flex;
  justify-content: space-between;
}

.sub-menu {
  background: #323639;
  padding: 5px;
}


.drawer.impresion .page-content .sub-menu .filename {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin-left: 15px;
}

.drawer.impresion .page-content .sub-menu .actions {
  display: flex;
  align-items: center;
}

.drawer.impresion .page-content .sub-menu .actions button {
  background-color: initial;
  font-size: 1.3rem;
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 36px;
}



.conatinerTiempos>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 15px !important;
}

.conatinerTiempos>.ant-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 15px !important;
}

.site-collapse-custom-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 0px !important;
  border-bottom: 0;
  border-radius: 2px;
}

.site-collapse-custom-panel {
  margin-bottom: 5px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px !important;
  border-radius: 2px;
}

.site-collapse-custom-tareas {
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  background-color: #fff;
  /*border: 1px solid #d9d9d9;*/
  border-radius: 2px;
}

.included {
  position: absolute;
  color: #909090;
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  background: #F0F0F0;
  display: block;
  width: 92px;
  height: 26px;
  margin: 0px 6px 0px 6px;
  text-align: center;
  text-transform: uppercase;
}

.followup {
  position: absolute;
  color: #39972e;
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  background: #e2efe0;
  display: block;
  width: 92px;
  height: 26px;
  margin: 0px 6px 0px 6px;
  text-align: center;
  text-transform: uppercase;
}



.times {
  display: block;
  background: #fff;
  height: 80px;
  border-bottom: solid #dbdbdb 1px;
}

.times .jobs {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.times .time {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  border-right: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.parts {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.times a.detail {
  float: left;
  margin: 28px 0px 0px 20px;
  line-height: 26px;
  font-size: 13px;
  font-family: 'Helvetica Neue', Arial;
  font-weight: bold;
  color: #006dc1;
}

.times .time .time-overview,
.times .jobs .jobs-count {
  font-family: 'Helvetica Neue', Arial;
  font-size: 20px;
  font-weight: bold;
  margin: 0px 20px 0px 20px;
}


.repair-times-detail {
  position: absolute;
  z-index: 999;
  display: block;
  height: 0px;
  overflow: hidden;
  background: #f6f6f6;
  width: 96%;
  padding: 0px 0px 0px 0px;
  border-bottom: none;
  -webkit-transition: all ease-in-out 0.15s;
  margin-top: -24px;
}

.repair-times-detail.active {
  display: block;
  padding: 20px 0px 20px 0px;
  height: auto;
  min-height: 1px;
  border-bottom: solid #dbdbdb 1px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
  -webkit-transition: all ease-in-out .25s;
}


.pSide0 {
  padding-left: 0px;
  padding-right: 0px;
}

.logoSerca {
  position: absolute;
  left: calc(65% - 89px);
  top: calc(43vh);
}

.txtLogin,
txtLogin:focus,
.txtLogin:hover {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #0068D2 !important;
  padding-left: 0px;
  padding-right: 0px;
}




.flex_container_Matricula {
  display: flex;

}

.flex_container_Matricula>div {

  margin: 8px;
  font-size: 16px;
}



/* effect-3 */

.copyright {
  font-size: 25px;
  font-weight: 100;
  color: #fff;
  text-align: center;
  margin-top: 100px;
}

/* Tabs Start */

.ease {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.tabs {
  background: #fff;
  position: relative;
  margin-bottom: 50px;
  font-size: 16px;
}

.tabs>input,
.tabs>span {
  width: 33.5%;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 0;
}

.tabs>input {
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
  z-index: 99;
}

.tabs>span {
  background: #fff;
  text-align: center;
  overflow: hidden;
  cursor: pointer;

  border-bottom: #999 1px solid;

}

.tabs>span i,
.tabs>span {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.tabs>input:hover+span {
  background: rgba(255, 255, 255, .1);
}

.tabs>input:checked+span {
  background: #fff;
}

.tabs>input:checked+span,
.tabs>input:hover+span {
  color: #3498DB;
}

#tab-1,
#tab-1+span {
  left: 0;
}

#tab-2,
#tab-2+span {
  left: 33.5%;
}

#tab-3,
#tab-3+span {
  left: 66.5%;
}



.tab-content {
  padding: 80px 20px 20px;
  width: 100%;
  min-height: 340px;
}

.tab-content section {
  width: 100%;
  display: none;
}

.tab-content section h1 {
  margin-top: 15px;
  font-size: 100px;
  font-weight: 100;
  text-align: center;
}

#tab-1:checked~.tab-content #tab-item-1 {
  display: block;
}

#tab-2:checked~.tab-content #tab-item-2 {
  display: block;
}

#tab-3:checked~.tab-content #tab-item-3 {
  display: block;
}


.effect-3 .line {
  border-bottom: #006dc1 2px solid;

  width: 33.5%;
  height: 4px;
  position: absolute;
  top: 56px;
}

#tab-1:checked~.line {
  left: 0;
}

#tab-2:checked~.line {
  left: 33.5%;
}

#tab-3:checked~.line {
  left: 66.5%;
}

.efectoContainerVehiculo {
  background-color: #4285f4;
  margin: 10px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  width: calc((100% / 3) - 20px) !important;
  font-weight: 700;
}

@media (max-width: 900px) {
  .efectoContainerVehiculo {
    width: calc((100% / 2) - 20px) !important;

  }
}




.efectoContainerVehiculo:hover {
  background-color: #495057 !important;
  color: #fff;
}

.botonNuevo {
  height: 40px;
  border: 0;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #4285f4;
  background-color: #efefef;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  line-height: 40px;
}


.containerFiltroNeumatico {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #dcdcdc;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}

.containerFiltroNeumatico>div {
  background-color: #eeeeee;
  padding: 5px;
  margin: 10px;
  color: #000;
  border-radius: 3px;
  height: 31px;
  cursor: pointer;
}







.fuenteTituloTexto {
  font-weight: 200;
  font-size: 16px;
  color: #053f75;
  text-transform: uppercase;

  padding-bottom: 20px;
}

.fuenteSubtituloTexto {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.fechaBoletin {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #1890ff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
}

.fuenteTituloItemTexto {
  font-weight: 500;
  font-size: 14px;
  color: #1890ff;
  text-transform: uppercase;

}

.tituloTxtBoletiones {
  font-size: 16px;
  color: #000;
  font-weight: 200;
  text-transform: uppercase;
}


.descripcionItem {
  font-weight: 300;
  font-size: 16px;
  color: #000;
  padding: 0px 10px 5px 10px;

}

.contenedorBuscadores {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*flex-wrap:  wrap-reverse*/
  ;
  /*justify-content: flex-start;*/
}

.contenedorBuscadores>div {
  padding: 5px;
  margin: 0px 10px 0px 10px;

}

.contenedorBuscadores>div>div {
  margin-top: 5px;
}

.contenedorBuscadores>div:nth-child(1) {
  flex: calc(20% - 10px);

}

.contenedorBuscadores>div:nth-child(2) {
  flex: calc(20% - 10px);
}

.contenedorBuscadores>div:nth-child(3) {
  flex: calc(30% - 10px);
}

.contenedorBuscadores>div:nth-child(4) {
  flex: calc(30% - 20px);
}


@media (max-width: 900px) {
  .contenedorBuscadores {
    flex-direction: column;
  }
}

.contenedorImagen {
  position: relative;

  border: 2px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;

}

.contenedorImagen>img {
  width: 200px;
  cursor: pointer;
}



/*CARD PARA LOS DOCUMENTOS*/

.containerFicheros {
  margin: 0px 0px 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.containerFicheros_card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 10px 10px 0px 0px;
}


.containerFicheros_card_body {
  text-align: center;
  min-height: 1px;
  padding: 0.8rem;
  width: 140px;
  height: 140px;
  display: inherit;
  justify-content: center;
  align-items: center;
}

.containerFicheros_card_body>img {
  cursor: pointer;
  width: 90px;
  margin-left: auto;
  margin-right: auto;
}

/*=======FIN DE CARD DOCUMENTOS ======*/


#getFile {
  display: none;
}

#getFileLabel {

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 10px 10px 0px 0px;

  text-align: center;
  min-height: 1px;
  padding: 0.8rem;
  width: 140px;
  height: 140px;
  display: inherit;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  height: 100%;
}

.image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;

}

.image-mask:hover {
  opacity: 1;
}

.image-mask-info>label {
  display: block;
  font-size: 16px;
  cursor: pointer;

}

.hficheros {
  color: #4285f4;
  display: block;
  width: 100%;
  margin: 20px 0px 10px 0px;

}

.ant-tabs-bar {
  margin: 0px !important;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  padding: 2px;
  color: #666;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.btn_modal_login {
  border-radius: 4px !important;
  padding: 0 15px !important;
  border: 1px solid transparent !important;
  font-size: 14px !important;
  border-color: rgb(118, 118, 118) !important;
}

.btn_modal_login_salir {
  background-color: #fff !important;
  color: #000 !important;
}


/*CARD PARA LAS CONSULTAS DESDE EL MOVIL*/

.containerConsultaCard {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: #b6b5b5;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  margin-bottom: 20px;
  cursor: pointer !important;

  margin-right: 5px;
}

.containerConsultaCard_item1 {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerConsultaCard_item1>img {
  width: 40px;
}

.containerConsultaCard_item2 {
  width: 75%;
  overflow: auto;
  overflow-x: hidden;

  font-size: 16px;
  font-weight: 500;
}

.consulta_titulo {
  font-size: 18px;
  font-weight: 600;
}

.consulta_titulo>span {
  font-weight: 800;
}

.separadorContenido {
  height: 2px;
  width: calc(100% / 4);
  background-color: #1890ff;
  border-radius: 5px;
  margin: 5px 0px;
}

.containerConsultas {
  height: calc(100vh - 170px);
  overflow: auto;
}

.consulta_vehiculo {
  height: 50px;
}

/*FIN CARD PARA LAS CONSULTAS DESDE EL MOVIL*/




.busquedaVehiculo {
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #1890ff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0px;


}




.v_consulta_Seleccionado {
  display: flex;
  align-content: flex-start;
  flex-direction: row;
  border-radius: 5px;
  /*margin-right: 10px;*/
  /*padding: 0 10px;*/
  background-color: #eee;


}

.v_consulta_Seleccionado_texto {
  width: calc(100% - 72px);
}

.v_consulta_Seleccionado_texto>input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  background-color: #eee;
  color: #000;
  font-size: 16px;
  border-radius: 5px;


}

.v_consulta_Seleccionado_texto>input:focus {
  border: none;
  outline: none;
}



.v_consulta_Seleccionado_remove {
  width: 5%;
  font-size: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  cursor: pointer !important;
  padding: 10px 20px;
}

.v_consulta_Seleccionado_info {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  padding: 10px 20px;

}

.v_consulta_Seleccionado_info>img {
  width: 26px;
  cursor: pointer !important;
}

.v_consulta_Seleccionado_barra {
  width: 2px;
  background-color: #ccc;
  margin: 5px 0px;
}

.containerVehiculo {
  padding: 10px 20px;
  background-color: #c9c9c9;

}

.bodyVehiculo {
  height: calc(100vh - 118px);

  padding: 20px;
  background-color: #dddd;
}

.historialVehiculo {
  background-color: #4285f4;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer !important;
  display: inline-flex;
}


.historialVehiculo>div {
  margin-right: 5px;
  max-width: calc((100vw / 3) - 40px);
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}


.bodyFichaVehciculo {
  height: calc(100vh - 118px);
  overflow: auto;
  padding: 20px;
  background-color: #dddd;
  display: flex;
  flex-wrap: wrap;
  color: #000;
  font-size: 24px;
  font-weight: 300;
}

.bodyFichaVehciculo_item1 {
  width: 60%;
  padding: 0px 10px 0px 0px;
}

.bodyFichaVehciculo_item1>table>tbody>tr>td {
  font-weight: 500;
  font-size: 16px;
}

.bodyFichaVehciculo_item2 {
  width: 40%;
}


.bodyFichaVehciculo_item3 {
  width: 100%;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 300;
}


.bodyFichaVehciculo_item3>table>tbody>tr>td {
  font-weight: 500;
  font-size: 16px;
}


.container_list_marcas {
  height: calc(100vh - 158px);
  overflow: auto;

}


.Visor_Telegram {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #151515;
  z-index: 10000 !important;


}

.Visor_Telegram_Menu {
  background-color: #2f2f2f;
  height: 60px;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 20px;
}


.Visor_Telegram_item_1 {
  width: 85%;
  color: #fff;
  height: inherit;
}

.Visor_Telegram_item_1>span:first-child {
  background-color: #080808;
  display: inline-flex;
  /*padding: 9px 20px 15px 20px;*/
  /*line-height: 36px;*/
  cursor: pointer;
  height: 60px;
  width: 60px;
  flex-direction: column;
  justify-content: center;
}

.Visor_Telegram_item_1>span:first-child:hover {
  background-color: #2f2f2f;
  cursor: pointer;
}

.Visor_Telegram_item_1>span:last-child {
  margin-left: 20px;
  vertical-align: middle;
  font-size: 16px;

}

.Visor_Telegram_item_2 {
  width: 15%;
  text-align: center;
  margin: 10px 40px;
  border-radius: 5px;
}

.Visor_Telegram_item_2>span {
  /*background-color: #4285f4;*/
  display: inline-block;
  justify-items: end;
  width: 100%;
  line-height: 40px;
  /*border-radius: 5px;*/
}

.Visor_Telegram_item_2>span:hover {
  /*background-color: #000;*/
  cursor: pointer;
}

.Container_doc_Visor_Telegram {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}

.Container_doc_Visor_Telegram>img {
  height: calc(100vh - 100px);
  object-fit: cover;
}


.colorBloqueoBotoneraFacturacion {
  background-color: #f7f7f7 !important;
}

.btnColor {
  background-color: #4285f4 !important;
}

.NuevoPadding {
  padding: 5px !important;
}

.cardImagenVehiculo {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);

}

.cardImagenVehiculoBody {
  margin: 0 auto;
}

.cardImagenVehiculoBody>img {
  height: 140px;
  cursor: pointer !important;
}

@media (max-width: 500px) {
  .cardImagenVehiculoBody>img {
    height: 80px;
  }
}

.Container_respuesta_tecnico {
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  height: calc(100vh / 3);
  overflow: auto;
  color: #3e3e3e;
  ;
}

.header_feedback {
  background-color: #007bff;
  width: 100% !important;

}

.header_feedback>div>button {
  background-color: #0F6AFB !important;
  width: 100% !important;
}

.body_feedback {
  background-color: #eeeeee;
  height: calc(100vh - 56px);
  width: 100%;
  /*overflow: auto;*/
  padding: 10px;
}

.body_container {
  height: calc(100vh - 173px);
  padding: 30px;
  width: 100%;
  overflow: auto;
}

.body_botones {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}

.body_botones>div {
  padding: 10px;
  background-color: #2E7FFF;
  /*margin: 10px 0px 0px 10px;*/
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer !important;
}

.body_botones>div:last-child {
  /*margin-right: 30px;*/
  background-color: #0F6AFB;
}

.body_botones>div>span {
  vertical-align: middle;
  font-size: 22px;
  margin-right: 10px;
}




.grado_feedback>span {
  font-size: 18px;
  font-weight: 500;
  color: #000;

}

.container_caras_feedback {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  /*margin-top: 10px;*/
  cursor: pointer !important;
}

.caras_feedback {
  width: calc((100% / 5) - 20px);
  margin: 10px;
}


@media (max-width: 900px) {
  .caras_feedback {
    width: calc((100% / 3) - 20px);
  }
}

.caras_feedback>img {
  height: 60px;
  /*
  display: flex;
  margin: 0 auto;
  */
}


.texto_grado_feedback {
  margin-top: 30px;
}

.texto_grado_feedback>span {
  font-size: 18px;
  font-weight: 500;
  color: #000;


}

.texto_grado_feedback>textarea {
  margin-top: 10px;
  /*height: ;*/
}


.textoAlertaUsuario {
  font-size: 20px;
  font-weight: 600;
}

.ant-btn-modalAlertas {

  padding: 8px 20px 20px 20px !important;

  border-radius: 2px !important;
}


.continaerVideo{
  display: flex;
  justify-content:center;
  flex-direction: column;
  height: calc(100vh - 53px);
  overflow: auto;
  overflow-x: hidden;
}
.continaerVideo > video{
  padding: 0px 20px;
}

.button_Conector{
  margin-top: 8px;
  border-radius: 2px;
  background-color:#1890ff;
  padding: 6px 20px;
  cursor: pointer !important;

  color: #fff;
  font-size: 18px;
}

.button_Conector > span{
  vertical-align: baseline;
}

.container_popover > img{
  height: 200px;
}



.containerPublicidadAssit{
  padding: 20px;
  background-color: #3a3939;
  color: #fff;
  height: calc(100vh - 56px);
  overflow: auto;
}
